body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.vq-intro-page{
    position:relative;
    /* background-image: url(https://s3.amazonaws.com/akcelita-artsense/images/background/pattern-gray.jpg); */
    /* background-size: cover; */
    color: #FFF;
    background:#ffffff;
    padding:130px 0px 100px;
}

.vq-intro-page:before{
    content:'';
    position:absolute;
    left:0px;
    margin-left:-40px;
    top:0px;
    width:50%;
    height:100%;
    background: url(/images/background/bg-shape-five.png) right center no-repeat;
}

@import './App';
@import '../node_modules/bulma/bulma.sass';
