@import './assets/fonts/gotham/style.css';

#root {
    font-family: 'Gotham Thin Regular';
}

.is-horizontal-center {
    justify-content: center;
}

.page-title {
    font-size: 1.6em !important;
    font-weight: bold !important;
}

.App-link {
    color: #61dafb;
}

.image-container {
    display: flex;
    justify-content: center;
}
.calendar-text {
    font-family: 'arial';
    position: absolute;
    top: 52%;
    left: 52%;
    font-size: 2.5em;
    transform: translate(-52%, -52%);
    text-align: center !important;
}

.ticket-text {
    font-family: 'arial';
    position: absolute;
    top: 48%;
    left: 50%;
    font-size: 2em;
    transform: translate(-48%, -50%);
    text-align: center !important;
}

.is-horizontal-center {
    justify-content: center;
}

.top {
    padding-top: 15px !important;
}

.hero-body.main {
    padding-top:0;
}

.top-back {
    &.main {
        margin-bottom: 0 !important;
        padding-top: 10px;
    }
    /* padding-top: 15px !important; */
    &.has-back{
        background-color: rgba(0, 0, 0, 0.3) !important;
    }
}

.register-input {
    border-radius: 0 !important;
    border-width: 0px 0px 2px 2px !important;
    box-shadow: 0px 0px 0px 0px !important;
    // background-color: rgba(255, 255, 255, 0) !important;
    color: black !important;
}

.declaimer {
    font-size: 11px;
}

.back-button {
    border-radius: 0px !important;
    border-width: 2px !important;
    background-color: rgba(255, 255, 255, 0) !important;
    color: white !important;
}

.square-button {
    // border-radius: 0px !important;
    // border-width: 2px !important;
    padding: 5px 35px 5px 35px !important;
    background-color: rgba(255, 255, 255, 0) !important;
    // color: white !important;
}

.language-button {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 1000;
}

.task {
    border-radius: 0px !important;
    border-width: 2px !important;
    padding: 5px 35px 5px 35px !important;
}

.borderless {
    border-radius: 0 !important;
    border-width: 0px !important;
    box-shadow: 0px 0px 0px 0px !important;
}

.text-wait-time,
.text-host,
.text-current-turn,
.text-current-turn-ticket,
.text-waiting-count,
.text-your-turn,
.text-notification-box,
.text-turn-ticket,
.text-transaction-type,
.text-placeholder,
.text-instructions-title,
.text-instructions-subtitle {
    color: white !important;
}

.no-padding {
    padding: 0 !important;
}

.ticketNumber {
    font-size: 50px !important;
    font-weight: bold !important;
    color: rgb(255, 255, 255) !important;
}

.ticket {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
}

// Use height and width properties to adjust size instead of background-size
.party {
    background-image: url('./assets/partyImgBlack.png');
    background-position: bottom right;
    background-size: contain !important;
    background-repeat: no-repeat;
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    height: 30px !important;
    width: 30px !important;
    padding-left: 200px;
}

// Font-fam is different because the root one is too thin for this usecase.
.partyAmount {
    font-size: 18 !important;
    font-weight: bold !important; 
    color: rgb(255, 255, 255) !important;
    font-family: "Lucida Console", Courier, monospace !important;
}

.bottom {
    padding: 10px !important;
    background-color: rgb(50, 100, 180) !important;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #606060 !important;
    opacity: .7; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white !important;
}

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}
.lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.text-current-turn{
    white-space: pre;
}

.card.current-turn{
    margin: 15px;
    border-radius: 10px;
    padding: 0.5rem;
}
